<template>
    <b-overlay :show="showLoader">
        <div class="misc-wrapper text-center">
            <div class="misc-inner p-2 p-sm-3 w-100">
                <b-row align-h="center" align-v="center" class="justify-content-center">
                    <b-col v-if="status !== null" cols="12" sm="6">
                        <h1>{{statusText}}</h1>
                        <rounded-button class="mt-4" :block="true" @click="$router.push({name: 'my-orders'})">Moja naročila</rounded-button>
                    </b-col>
                </b-row>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import {BOverlay, BRow, BCol} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton'
    export default {
        components: {RoundedButton, BOverlay, BRow, BCol},
        data() {
            return {
                showLoader: false,
                object: {
                    payment_intent: '',
                    payment_intent_client_secret: '',
                    redirect_status: ''
                },
                statusText: '',
                status: null
            }
        },
        methods: {
            async checkStatus() {
                const thisIns = this
                thisIns.showLoader = true

                await thisIns.$http.get(`/api/user/v1/stripe/status/${this.object.payment_intent}`)
                    .then(res => {
                        switch (res.data) {
                        case true:
                            this.statusText = 'Uspešen nakup!'
                            break
                        case false:
                            this.statusText = 'Vaše plačilo ni bilo uspešno, poskusite znova!'
                            break
                        default:
                            this.statusText = 'Nekaj je šlo narobe!'
                            break
                        }

                        this.status = res.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            setParams() {
                const query = Object.assign({}, this.$route.query)

                this.object =  {
                    payment_intent: query.payment_intent,
                    payment_intent_client_secret: query.payment_intent_client_secret,
                    redirect_status: query.redirect_status
                }

                if (this.object.payment_intent === '') {
                    this.$router.replace('/')
                }

                delete query.payment_intent
                delete query.payment_intent_client_secret
                delete query.redirect_status
                this.$router.replace({ query })
            }
        },
        async mounted() {
            await this.setParams()
            await this.checkStatus()
        }
    }
</script>

<style>
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>